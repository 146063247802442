body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body.select-dropdown-bulk {
  margin-left: -56px !important;
  width: 100% !important;
}
body
  .select-dropdown-bulk
  .rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #f0f5f5;
}
body
  .select-dropdown-bulk
  .rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner
  .ant-select-item {
  padding: 0;
}
body
  .select-dropdown-bulk
  .rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner
  .ant-select-item-option-state {
  display: none !important;
}
body
  .select-dropdown-bulk
  .ant-select-item-option-active
  .select-item-option-quantity-active {
  background-color: #f0f5f5;
}
body
  .select-dropdown-bulk
  .rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner
  .ant-select-item-option-selected {
  background-color: #f0fcfc !important;
}

body .select-dropdown {
  margin-left: -56px !important;
  @media screen and (min-width: 1280px) {
    width: 1080px !important;
  }
  @media screen and (max-width: 1280px) and (min-width: 1024px) {
    width: 90% !important;
  }
  @media screen and (max-width: 1024px) {
    width: 90% !important;
  }
}
body
  .select-dropdown
  .rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner
  .ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  background-color: #99f0f0;
}
body
  .select-dropdown
  .rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner
  .ant-select-item {
  padding: 0;
}
body
  .select-dropdown
  .rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner
  .ant-select-item-option-state {
  display: none !important;
}
/* this is for the active add quantity background */
body
  .select-dropdown
  .ant-select-item-option-active
  .select-item-option-quantity-active {
  background-color: #99f0f0;
}
/* this is for the active background of option item */
body
  .select-dropdown
  .ant-select-item-option-active
  .select-item-option-active {
  background-color: #99f0f0;
}
body
  .select-dropdown
  .rc-virtual-list
  .rc-virtual-list-holder
  .rc-virtual-list-holder-inner
  .ant-select-item-option-selected {
  background-color: #e0ebea !important;
}

/* https://stackoverflow.com/questions/2781549/removing-input-background-colour-for-chrome-autocomplete */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 100px white inset !important;
}
/* The above is needed as chrome adds some colour when we are selecting some auto fill input */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

body {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}
